import React from 'react';
export default () => (
  <div
    className='page-404'
    style={{
      height: '100vh',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    }}
  >
    <h1>I didn't get that! 🙂</h1>
  </div>
);
